interface RoomLimitProps {
  show: boolean
  minutesLeft: number | undefined
  minutesLimit: number | undefined
  roomHeader?: boolean
}

export default function RoomLimit({ show, roomHeader, minutesLeft, minutesLimit }: RoomLimitProps) {
  if (!show || minutesLeft === undefined || minutesLimit === undefined) {
    return <></>
  }
  const remainedHours = Number((minutesLeft / 60).toFixed(1))
  const hoursLimit = Number((minutesLimit / 60).toFixed(1))
  return (
    <div className={`inline-flex items-center px-3 py-1 rounded-full bg-emerald-100 text-emerald-800 shadow-sm
      ${remainedHours > 0.3 * hoursLimit ? 'bg-[rgb(174,216,195)]' : 'bg-[rgba(253,67,103,0.1)]'} 
      ${roomHeader && 'hidden lg:flex '}`}>
      <span className="mr-2" role="img" aria-label="clock">🕒</span>
      <div className="hidden sm:flex">Time left:
        {remainedHours}/{hoursLimit} hours
      </div>
      <div className="flex sm:hidden">Left: <br />
        {remainedHours} h.
      </div>
    </div>
  )
}
