import React, { FC, useState } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { FiHelpCircle, FiShare2 } from 'react-icons/fi'
import { BsCheckCircle } from 'react-icons/bs'
import NeedHelpModal from './NeedHelpModal'
import { useAuthContext } from '../../../hooks/AuthContext'
import { BASE_URL } from '../../../env'
import classes from './Header.module.scss'
import Logo from 'components/common/logo/Logo'
import { useRoomContext } from 'hooks/Room'
import RoomLimit from 'components/room-page/roomlimit/RoomLimit'
import { useRoomRequest } from '../../../hooks/useRoomRequest'

export interface RoomHeaderProps {
  roomId?: string
}

const RoomHeader: FC<RoomHeaderProps> = ({ roomId }) => {
  // const router = useRouter()
  // const newUser = useContext(NewUserContext)
  const [copied, setCopied] = useState(false)
  // const [checked, setChecked] = useState(false)
  // const [href, setHref] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const { state } = useAuthContext()
  const { state: { currentRoom } } = useRoomContext()
  const {
    isHost,
  } = useRoomRequest({ roomId })
  // const toggleOption1 = 'original'
  // const toggleOption2 = 'new design'

  // function toggleRoom() {
  //   if (href.split('/')[3] === 'room') {
  //     router.push(href.replace('room', 'roomv2'))
  //   } else {
  //     router.push(href.replace('roomv2', 'room'))
  //   }
  // }

  // useEffect(() => {
  // const tempHref = window.location.href
  // setHref(tempHref)
  // if (tempHref.split('/')[3] === 'roomv2') {
  //   setChecked(true)
  // }
  // }, [])

  const sendHelpRequest = async () => {
    const roomLink = window.location.href
    try {
      const response = await fetch(`${BASE_URL}/api/v1/main/need-help`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${state.key}`,
        },
        body: JSON.stringify({
          room_link: roomLink,
        }),
      })
      if (response.ok && response.status === 200) {
        setModalShow(true)
        setRequestSent(true)
      } else {
        throw new Error('Error in req')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <NeedHelpModal modalShow={modalShow} setModalShow={setModalShow} />
      <div className="flex flex-col">
        <div
          className={classNames(
            'md:w-full items-center flex justify-between bg-white xl:py-2 pr-6',
          )}
        >
          <Link href="/" className={`${classes.logo} ${classes.link}`} target="_blank">
            <Logo roomHeader customLogoLink={currentRoom?.group.logo} />
          </Link>
          {/* {!newUser && ( */}
          {/*  <div className="hidden md:flex"> */}
          {/*    <Switch */}
          {/*      onToggle={() => toggleRoom()} */}
          {/*      option1={toggleOption1} */}
          {/*      option2={toggleOption2} */}
          {/*      defaultChecked={checked} */}
          {/*    /> */}
          {/*  </div> */}
          {/* )} */}
          <div className="items-center justify-between flex gap-x-2 md:gap-x-5 relative">
            <RoomLimit show={!state.info?.subsrciption} minutesLimit={state.info?.room_limits}
              minutesLeft={state.info?.room_limit_left} roomHeader />
            {isHost && (
              <button
                disabled={requestSent}
                // @ts-ignore
                onClick={sendHelpRequest}
                className={`font-poppins font-medium bg-rose-100 text-red-500 ease-in-out 
                                duration-[200ms] flex p-2 items-center rounded-lg hover:border-2 
                                ${
              requestSent
                ? 'cursor-not-allowed opacity-50'
                : 'hover:border-rose-300'
              }
                                border-2 border-transparent`}
              >
                <span className="hidden md:inline">Need Help</span>
                <FiHelpCircle className="md:ml-2" />
              </button>
            )}
            {roomId && (
              <p
                className={`p-2 font-poppins text-[16px] cursor-pointer rounded-lg 
                  bg-[#eaf4ff] ease-in-out duration-[400ms] flex items-center
                  hover:border-2 hover:border-blue-200 border-2 border-transparent
                  font-medium relative ${!copied && 'text-center'}`}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href)
                  setCopied(true)
                  setTimeout(() => {
                    setCopied(false)
                  }, 5000)
                }}
              >
                {copied ? (
                  <>
                    <span className="hidden md:inline">Copied</span>
                    <BsCheckCircle className="md:ml-2" />
                  </>
                ) : (
                  <>
                    <span className="hidden md:inline">Share Link</span>
                    <FiShare2 className="md:ml-2" />
                  </>
                )}
              </p>
            )}
          </div>
        </div>
        {/* {!newUser && ( */}
        {/*  <div className="flex md:hidden bg-white w-full justify-center pb-3"> */}
        {/*    <Switch */}
        {/*      onToggle={() => toggleRoom()} */}
        {/*      option1={toggleOption1} */}
        {/*      option2={toggleOption2} */}
        {/*      defaultChecked={checked} */}
        {/*    /> */}
        {/*  </div> */}
        {/* )} */}
      </div>
    </>
  )
}

export { RoomHeader }
