import { useEffect, useState } from 'react'
import { useAuthContext } from './AuthContext'
import { useRoomContext } from './Room'
import { BASE_URL } from 'env'
import { init, track } from '@amplitude/analytics-node'
import { ClientToServerEvents, ServerToClientEvents, createClientSocket } from 'lib/socket'
import { Socket } from 'socket.io-client'

interface UseRoomRequestProps {
  id: string
}

export const useRoomRequest = ({ id }: UseRoomRequestProps) => {
  const {
    state: { me, isAuth, key },
  } = useAuthContext()
  const { state, actions } = useRoomContext()
  const [connected, setConnected] = useState(false)
  const [socket, setSocket] = useState<Socket<
    ServerToClientEvents,
    ClientToServerEvents
  > | null>(null)
  const isHost = isAuth && state.currentRoom?.creator === me?.id
  const [error, setError] = useState()
  const [guestEmailEntered, setGuestEmailEntered] = useState(true)
  useEffect(() => {
    const guestEmailExpired = new Date().getTime() - Number(localStorage.getItem('guestEmailEnteredTime'))
      > 604800000
    setGuestEmailEntered(isHost || !guestEmailExpired)

    const updateQueryParamForAnalytics = (isHostValue: boolean) => {
      if (typeof window !== 'undefined') {
        const url = new URL(window.location.href)
        url.searchParams.set('authuser', isHostValue ? '1' : '0')
        window.history.replaceState({ ...window.history.state }, '', url)
      }
    }

    updateQueryParamForAnalytics(isHost)
  }, [isHost])

  const [wherebyEmbedLink, setWherebyEmbedLink] = useState('')
  const [isLoadingWherebyLink, setIsLoadingWherebyLink] = useState(false)

  init('b2b23f1f051a2f1a92a8a1f057ef34b3', null || me?.id)
  useEffect(() => {
    if (me) {
      track('Room opened [Auth]', {
        room_id: id,
        firstname: me.first_name,
        lastname: me.last_name,
        email: me.email,
      })
    } else {
      track('Room opened [Non-Auth]', { room_id: id })
    }
  }, [me?.id, id])
  const setConnection = async () => {
    setIsLoadingWherebyLink(true)
    try {
      let response
      if (isAuth) {
        response = await fetch(`${BASE_URL}/api/v1/main/rooms/${id}/`, {
          method: 'GET',
          headers: {
            Authorization: `Token ${key}`,
          },
        })
      } else {
        response = await fetch(`${BASE_URL}/api/v1/main/rooms/${id}/`, {
          method: 'GET',
        })
      }
      if (response.status === 200) {
        const data = await response.json()
        actions.setCurrentRoom({ ...data })
        setWherebyEmbedLink(data.url_host)
      } else {
        const errorData = await response.json()
        setError(errorData)
        // throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorData.message}`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingWherebyLink(true)
    }
  }

  useEffect(() => {
    setConnection()
  }, [id, isAuth])

  useEffect(() => {
    fetch('/api/socketio').finally(() => {
      if (socket !== null) {
        setConnected(socket.connected)
      } else {
        const newSocket = createClientSocket(id, me?.id || '')
        newSocket.on('connect', () => {
          setConnected(true)
        })
        setSocket(newSocket)
      }
    })

    return () => {
      if (socket !== null) {
        socket.disconnect()
        setSocket(null)
      }
    }
  }, [id, socket, isAuth, key, me?.id])

  const connectionCheck = () => {
    if (socket !== null && socket.connected) {
      setConnected(true)
      return
    }
    setTimeout(connectionCheck, 100)
  }

  return {
    error,
    socket,
    connected,
    connectionCheck,
    guestEmailEntered,
    setGuestEmailEntered,
    wherebyEmbedLink,
    isLoadingWherebyLink,
    isHost,
    emailModal: state.currentRoom?.collect_emails,
  }
}
