import Image from 'next/image'
import { useEnv } from '../../../useEnv'

interface LogoProps {
  roomHeader?: boolean
  customLogoLink?: string
}

export default function Logo({ roomHeader, customLogoLink }: LogoProps) {
  const env = useEnv()
  const { NEXT_PUBLIC_LOGO, NEXT_PUBLIC_APP_VERSION } = env
  return (
    <>
      {customLogoLink
        ? (
          <img
            src={customLogoLink}
            alt="user logo"
            loading="eager"
            className="w-[100px] h-[37px] object-contain"
          />
        )
        : (
          <Image
            src={NEXT_PUBLIC_LOGO}
            width={100}
            height={37}
            sizes="(max-width: 479px) 47vw, 194px"
            alt="default logo"
            priority
          />
        )}
      <div className={`${roomHeader && 'leading-3 xl:leading-4'} text-xs text-center`}>
        {NEXT_PUBLIC_APP_VERSION}
      </div>
    </>
  )
}
