import React, { useState, useEffect } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRoomContext } from '../../../hooks/Room'

function getInitialCountdown() {
  if (typeof window !== 'undefined') {
    const savedCountdown = localStorage.getItem('countdown')
    return savedCountdown ? JSON.parse(savedCountdown) : 120
  }
  return 120
}

export default function NeedHelpModal({ modalShow, setModalShow }) {
  const { state: { remoteParticipants } } = useRoomContext()
  const [countdown, setCountdown] = useState(getInitialCountdown)
  const [isCompleted, setIsCompleted] = useState(false)

  useEffect(() => {
    if (modalShow) {
      setCountdown(120)
      localStorage.removeItem('countdown')
    }
  }, [modalShow])

  useEffect(() => {
    if (remoteParticipants.length > 0) {
      setModalShow(false)
    }
  }, [remoteParticipants, setModalShow])

  useEffect(() => {
    const savedCountdown = localStorage.getItem('countdown')
    if (savedCountdown) {
      setCountdown(JSON.parse(savedCountdown))
    }
  }, [])
  useEffect(() => {
    let timer

    if (modalShow && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else if (countdown === 0) {
      setIsCompleted(true)
      localStorage.removeItem('countdown')
    }

    return () => clearTimeout(timer)
  }, [countdown, modalShow])

  useEffect(() => {
    if (countdown > 0) {
      localStorage.setItem('countdown', JSON.stringify(countdown))
    }
  }, [countdown])

  if (!modalShow && countdown > 0) {
    return <></>
  }

  const toastVariant = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  }
  return (
    <>
      <motion.div className="fixed top-20 right-4 z-50 shadow-lg"
        initial="hidden"
        animate="visible"
        variants={toastVariant}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-white rounded-lg p-4">
          {isCompleted && !remoteParticipants.length ? (
            <div className="text-[16px] max-w-[200px] font-bold ml-4">
              No one could join. Please
              <Link href="https://calendly.com/lala-dima/30min" className="text-rose-400"> book a demo call</Link>.
            </div>
          ) : (
            <>
              <div className="flex flex-row justify-between items-center p-2 w-full">
                <div className="text-[18px] text-rose-500 font-bold">Help is Coming Soon</div>
                {/* <AiOutlineClose */}
                {/*    size={'24px'} */}
                {/*    onClick={() => setModalShow(prev => !prev)} */}
                {/*    className="cursor-pointer hover:text-rose-400"/> */}
              </div>
              <div className="h-full flex flex-col justify-center">
                <div className="flex items-center">
                  <AiOutlineClockCircle size="48px" className="text-rose-500 animate-spin" />
                  <div className="text-[16px] font-bold ml-4">
                    Hang tight, assistance will arrive in<br />
                    <span
                      className="text-[16px] text-rose-600">{Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}{countdown % 60}
                    </span> minutes
                  </div>
                </div>
                <div className="text-gray-500 mt-2">Stay where you are, we're on our way!</div>
              </div>
            </>

          )}
        </div>
      </motion.div>
    </>
  )
}
