import { useAuthContext } from 'hooks/AuthContext'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import classes from './Header.module.scss'
import Logo from 'components/common/logo/Logo'

export interface HeaderProps {
  isSubscribed?: boolean,
}

const Header: FC<HeaderProps> = ({ isSubscribed }) => {
  const router = useRouter()
  const { state } = useAuthContext()
  return (
    <div
      className={classNames(
        classes.header,
        'md:w-full md:flex md:items-center md:justify-between items-center flex justify-center',
      )}
    >
      <div>
        <Link href="/" className={`${classes.logo} ${classes.link}`}>
          <Logo />
        </Link>
      </div>

      <div className="items-center justify-between flex gap-x-5 md:gap-x-10  ">
        {/* <Link href="/">
                    <p className="text-[12px] md:text-[16px]">Home </p>
                </Link> */}
        <Link href="https://www.meetlala.io/certification-center">
          <p className="text-[12px] md:text-[16px]">Book Demo </p>
        </Link>
        {/* <Link href="/">
                    <p className="text-[12px] md:text-[16px]">Pricing</p>
                </Link> */}
        <Link href="https://www.meetlala.io/blog">
          <p className="text-[12px] md:text-[16px]">Blog</p>
        </Link>
        {!isSubscribed && (
          <Link href="/subscribe">
            <p className="text-[12px] md:text-[16px]">Subscribe</p>
          </Link>
        )}

      </div>

      <div className={classNames(classes.content, '')}>
        {state.isAuth ? (
          <p
            className="md:pr-10 text-[12px] md:text-[16px]"
            onClick={() => {
              window.localStorage.clear()
              router.push('/')
              window.location.reload()
            }}
          >
            Logout
          </p>
        ) : (
          <div className="flex">
            <p
              className="md:pr-5 text-[12px] md:text-[16px]"
              onClick={() => router.push('/registration')}
            >
              Register
            </p>
            <p
              className="md:pr-10 text-[12px] md:text-[16px]"
              onClick={() => router.push('/login')}
            >
              Login
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
//
export { Header }
