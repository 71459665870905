import React, { FC, ReactNode } from 'react'
import NoScriptAlert from '../alerts/NoScriptAlert'
import Head, { MetaProps } from './Head'
import { RoomHeader } from '../header'

interface Props {
  meta: MetaProps
  showNavbar?: boolean
  roomId?: string
  children: ReactNode
  contentClasses?: string
}

const Layout: FC<Props> = ({ meta, showNavbar = true, roomId, children, contentClasses }) => (
  <div className="flex flex-col min-h-screen overflow-auto">
    <Head customMeta={meta} />
    {showNavbar && (
      <header>
        <RoomHeader roomId={roomId} />
      </header>
    )}
    <noscript>
      <NoScriptAlert />
    </noscript>

    <main className={`${contentClasses} relative flex flex-col grow p-4`}>{children}</main>
  </div>
)

export default Layout
